// See React on Rails Pro documentation for details regarding **.imports-X.** file extension & duplicate file structure.
import loadable from '@loadable/component';
import retry from '../../utils/retry';

// Routes
export const BoeingHome = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BoeingHome" */
  './BoeingHome')));
export const BoeingSchedule = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BoeingSchedule" */
  './BoeingSchedule')));
export const BoeingLocation = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BoeingLocation" */
  './BoeingLocation')));
export const BoeingError = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "BoeingError" */
  './BoeingError')));